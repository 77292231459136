import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { API_BASE_URL } from 'constants/environment';
import {
  IApiOnePageResponse,
  IApiPageResponse,
  IApiResponse,
} from 'types/general';
import {
  IAdditionalService,
  IAdditionalServiceParams,
  IGetOrderInfo,
  IGetOrderInfoParams,
  IMakeOrderParams,
  IMakeOrderResult,
  ITimeSlot,
  ITimeSlotParams,
  ITotalSetPrice,
  ITotalSetTime,
  ITotalSetTimeParams,
  IUnitedData,
  IUnitedDataParams,
  IPublicParams,
  IPublicParamsResponse,
} from 'types/types';

export const publicApi = createApi({
  reducerPath: 'publicApi',
  baseQuery: fetchBaseQuery({ baseUrl: API_BASE_URL }),
  tagTypes: ['AvailableSlots'],
  endpoints: builder => ({
    getUnitedData: builder.query<IApiResponse<IUnitedData>, IUnitedDataParams>({
      query: params => ({
        url: '/get_united_data',
        method: 'POST',
        body: {
          params,
        },
      }),
    }),
    getRentalItems: builder.query<unknown, string>({
      query: () => ({
        url: '/get_rental_items/',
        method: 'POST',
      }),
    }),
    getAvailableSlots: builder.query<
      IApiPageResponse<ITimeSlot>,
      ITimeSlotParams
    >({
      query: params => ({
        url: '/get_available_slots/',
        method: 'POST',
        body: {
          params: {
            ...params,
            items: params.items.join(','),
          },
        },
      }),
      providesTags: ['AvailableSlots'],
    }),
    getItemServices: builder.query<
      IApiPageResponse<IAdditionalService>,
      IAdditionalServiceParams
    >({
      query: params => ({
        url: '/get_item_services/',
        method: 'POST',
        body: {
          params,
        },
      }),
    }),
    makeOrder: builder.mutation<
      IApiOnePageResponse<IMakeOrderResult>,
      IMakeOrderParams
    >({
      query: params => ({
        url: '/make_order/',
        method: 'POST',
        body: {
          params: {
            ...params,
            orders: `[${params.orders
              .map(order =>
                JSON.stringify({
                  ...order,
                  ordered_item_service_ids:
                    order.ordered_item_service_ids.join(','),
                }),
              )
              .join(',')}]`,
          },
        },
      }),
      invalidatesTags: ['AvailableSlots'],
    }),
    getOrderInfo: builder.query<
      IApiOnePageResponse<IGetOrderInfo>,
      IGetOrderInfoParams
    >({
      query: params => ({
        url: '/get_order_info/',
        method: 'POST',
        body: {
          params,
        },
      }),
      transformResponse: (value: IApiOnePageResponse<IGetOrderInfo>) => {
        const result = { ...value };

        result.data.result.orders = value.data.result.orders.map(order => {
          try {
            return {
              ...order,
              params:
                typeof order.params === 'string'
                  ? JSON.parse(order.params)
                  : order.params,
            };
          } catch (e) {
            return order;
          }
        });

        return value;
      },
    }),
    getTotalSetTime: builder.query<
      IApiOnePageResponse<ITotalSetTime>,
      ITotalSetTimeParams
    >({
      query: params => ({
        url: '/get_total_set_time/',
        method: 'POST',
        body: {
          params: {
            public_id: params.public_id,
            orders: `[${params.orders
              .map(order =>
                JSON.stringify({
                  ...order,
                  ordered_item_service_ids:
                    order.ordered_item_service_ids.join(','),
                }),
              )
              .join(',')}]`,
          },
        },
      }),
    }),
    getTotalSetPrice: builder.query<
      IApiOnePageResponse<ITotalSetPrice>,
      ITotalSetTimeParams
    >({
      query: params => ({
        url: '/get_total_set_price/',
        method: 'POST',
        body: {
          params: {
            public_id: params.public_id,
            orders: `[${params.orders
              .map(order =>
                JSON.stringify({
                  ...order,
                  ordered_item_service_ids:
                    order.ordered_item_service_ids.join(','),
                }),
              )
              .join(',')}]`,
          },
        },
      }),
    }),
    getPublicParams: builder.query<
      IApiOnePageResponse<IPublicParamsResponse>,
      IPublicParams
    >({
      query: params => ({
        url: '/get_public_params/',
        method: 'POST',
        body: {
          params,
        },
      }),
    }),
  }),
});
