import React, { useMemo } from 'react';
import { useTheme } from '@emotion/react';

import { ITimeSlot } from 'types/types';

import { DayPartSlots } from '../DayPartSlots';
import { isSlotDisabled } from '../utils';

import { StyledTimeSlots } from './TimeSlots.styles';

interface ISlowRow {
  slots: ITimeSlot[];
  title: 'MORNING' | 'DAY' | 'EVENING';
  color: string;
}

interface ITimeSlotsProps {
  selected: ITimeSlot[];
  onSelect: (slots: ITimeSlot[]) => void;
  timeSlots: ITimeSlot[];
}

export const TimeSlots = ({
  selected,
  onSelect,
  timeSlots,
}: ITimeSlotsProps) => {
  const theme = useTheme();
  const slotsRows = useMemo(() => {
    const result: ISlowRow[] = [
      {
        slots: [],
        title: 'MORNING',
        color: `${theme.palette.periods.morning}`,
      },
      {
        slots: [],
        title: 'DAY',
        color: `${theme.palette.periods.day}`,
      },
      {
        slots: [],
        title: 'EVENING',
        color: `${theme.palette.periods.evening}`,
      },
    ];

    for (const slot of timeSlots) {
      if (isSlotDisabled(slot)) {
        continue;
      }

      const hours = Number(slot.slot.slice(0, 2));

      if (hours < 13) {
        result[0].slots.push(slot);
      } else if (hours >= 13 && hours < 18) {
        result[1].slots.push(slot);
      } else {
        result[2].slots.push(slot);
      }
    }

    return result;
  }, [timeSlots]);

  return (
    <StyledTimeSlots>
      {slotsRows.map((row, index) =>
        row.slots.length > 0 ? (
          <DayPartSlots
            key={index}
            title={row.title}
            color={row.color}
            slots={row.slots}
            selected={selected}
            onSelect={onSelect}
          />
        ) : null,
      )}
    </StyledTimeSlots>
  );
};
