import React from 'react';
import { useTranslation } from 'react-i18next';
import { skipToken } from '@reduxjs/toolkit/query';
import { Typography } from '@mui/material';

import { publicApi } from 'store/api/public.ts';

import { useSafeParams } from 'hooks/useSafeParams.ts';
import { ServiceMiniCard } from 'components/ServiceMiniCard/ServiceMiniCard.tsx';
import { IResultStepOrderItem } from 'features/resultStep/types.ts';
import { ServiceSlots } from 'features/resultStep/ServiceSlots/ServiceSlots.tsx';
import { IAdditionalServicesResult } from 'types/types.ts';

import { Root } from './ServiceOrderInfo.styles.tsx';

type TServiceOrderInfoProps = {
  additionalServices: IAdditionalServicesResult[];
  serviceOrderItem: IResultStepOrderItem;
};

export const ServiceOrderInfo = (props: TServiceOrderInfoProps) => {
  const { additionalServices, serviceOrderItem } = props;
  const { t } = useTranslation();

  const { publicId } = useSafeParams();
  const { data: totalSetTime } = publicApi.useGetTotalSetTimeQuery(
    publicId
      ? {
          public_id: publicId,
          orders: serviceOrderItem.slots.map((slot) => ({
            rental_item_id: slot.rental_item_id,
            from: slot.from,
            to: slot.to,
            ordered_item_service_ids: slot.ordered_item_service_ids,
            comment: undefined,
          })),
        }
      : skipToken
  );

  return (
    <Root>
      <ServiceMiniCard
        item={serviceOrderItem.service!}
        label={
          totalSetTime?.data.result?.total_set_time
            ? t('RENT_TIME', {
                time: totalSetTime?.data.result.total_set_time,
              })
            : ''
        }
      />
      <Typography
        fontSize={14}
        fontWeight={600}
        marginTop="20px"
        marginBottom="16px"
      >
        {t('PRICE_BOOKING')}
      </Typography>
      <ServiceSlots
        slots={serviceOrderItem.slots}
        additionalServices={additionalServices}
      />
    </Root>
  );
};
