import React from 'react';
import { useTranslation } from 'react-i18next';
import { skipToken } from '@reduxjs/toolkit/query';
import { FormProvider } from 'react-hook-form';

import { publicApi } from 'store/api';
import { getOrderItems } from 'store/slices/order';
import { useAppSelector } from 'store/hooks';

import { OrderDetails, StepAction } from 'features/order';
import { IAdditionalServiceOrder, IRentalItem, ITimeSlot } from 'types/types';
import { useSafeParams } from 'hooks/useSafeParams';
import { useDateLocale } from 'i18n/useDateLocale';
import { useForm } from 'hooks/useForm';
import { ServiceMiniCard } from 'components/ServiceMiniCard';

import { Root, Main, ServiceItem } from './AdditionalService.styles';
import { AdditionalServiceSettings } from './AdditionalServiceSettings';
import { IAdditionalServiceForm } from './schema';

interface IAdditionalServiceProps {
  services: IRentalItem[];
  slots: ITimeSlot[];
  onSubmit: (additionalServiceOrders: IAdditionalServiceOrder[]) => void;
  onRemoveSlot?: (slot: ITimeSlot) => void;
}

export const AdditionalService = ({
  services,
  slots,
  onSubmit,
  onRemoveSlot,
}: IAdditionalServiceProps) => {
  const { t } = useTranslation();
  const locale = useDateLocale();

  const defaultAdditionalServiceOrders = useAppSelector(
    (state) => state.order.additionalServiceOrders
  );
  const { publicId } = useSafeParams();
  const { data: totalSetTime } = publicApi.useGetTotalSetTimeQuery(
    publicId
      ? {
          public_id: publicId,
          orders: getOrderItems({
            services,
            slots,
            additionalServiceOrders: [],
          }),
        }
      : skipToken
  );

  const formMethods = useForm<IAdditionalServiceForm>({
    defaultValues: {
      additionalServiceOrders: defaultAdditionalServiceOrders,
    },
  });

  return (
    <Root>
      <Main>
        <FormProvider {...formMethods}>
          {services.map((service) => (
            <ServiceItem key={service.id}>
              <ServiceMiniCard
                item={service}
                label={
                  totalSetTime?.data.result?.total_set_time
                    ? t('RENT_TIME', {
                        time: totalSetTime?.data.result.total_set_time,
                      })
                    : ''
                }
              />

              <AdditionalServiceSettings
                className="service-settings"
                service={service}
                slots={slots}
                onRemoveSlot={onRemoveSlot}
              />
            </ServiceItem>
          ))}
        </FormProvider>
      </Main>
      <StepAction
        disabled={slots.length === 0}
        onSubmit={() =>
          onSubmit(formMethods.getValues('additionalServiceOrders'))
        }
        label={t('NEXT')}
      >
        <OrderDetails
          services={services}
          slots={slots}
          additionalServiceOrders={formMethods.watch('additionalServiceOrders')}
        />
      </StepAction>
    </Root>
  );
};
