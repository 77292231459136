import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import { IQuickBookTimeSlot } from 'types/admin';
import { IAdditionalServiceOrder, IOrderItem } from 'types/types';
import { parseApiDate } from 'utils/parseApiDate';

export interface IQuickBookState {
  slots: IQuickBookTimeSlot[];
}

const initialState: IQuickBookState = {
  slots: [],
};

export const quickBookSlice = createSlice({
  name: 'quickBook',
  initialState,
  reducers: {
    setSlots: (state, action: PayloadAction<IQuickBookTimeSlot[]>) => {
      const result = [...action.payload];

      result.sort(
        (a, b) =>
          parseApiDate(a.slot.from).getTime() -
          parseApiDate(b.slot.from).getTime()
      );

      state.slots = result;
    },
    removeSlot: (state, action: PayloadAction<IQuickBookTimeSlot>) => {
      state.slots = state.slots.filter((slot) => {
        if (slot.serviceId === action.payload.serviceId) {
          return slot.slot.from !== action.payload.slot.from;
        } else {
          return true;
        }
      });
    },
  },
});

export default quickBookSlice.reducer;

/**
 * Соединяет между собой услуги и слоты и создает массив заказов.
 * TODO: объеденить с getOrderItems: изменить везде на IQuickBookTimeSlot (переименовать на универсальный тип)
 */
export const quickBookGetOrderItems = ({
  slots,
  additionalServiceOrders,
  remindTime,
}: {
  slots: IQuickBookTimeSlot[];
  additionalServiceOrders: IAdditionalServiceOrder[];
  remindTime?: string;
}): IOrderItem[] => {
  const result: IOrderItem[] = [];

  slots.forEach((slot) => {
    const additionalServicesIds = additionalServiceOrders
      .filter(
        (item) =>
          item.serviceId === slot.serviceId && item.slotFrom === slot.slot.from
      )
      .map((item) => item.additionalServiceId);

    result.push({
      is_paid: slot.slot.is_paid,
      rental_item_id: slot.serviceId,
      from: slot.slot.from,
      to: slot.slot.to,
      ordered_item_service_ids: additionalServicesIds,
      comment: undefined,
      params: {
        custom_interval: remindTime,
      },
    });
  });

  return result;
};
