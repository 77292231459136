import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';

import orderReducer from './slices/order';
import quickBookReducer from './slices/quickBook';
import { publicApi, privateApi } from './api';

export const store = configureStore({
  reducer: {
    order: orderReducer,
    quickBook: quickBookReducer,

    [publicApi.reducerPath]: publicApi.reducer,
    [privateApi.reducerPath]: privateApi.reducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat(publicApi.middleware, privateApi.middleware),
});

setupListeners(store.dispatch);

export type TRootState = ReturnType<typeof store.getState>;
export type TAppDispatch = typeof store.dispatch;
