import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Typography } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/query';
import { Paths } from 'routers/constants';
import { isCancel } from 'axios';

import { publicApi } from 'store/api';

import { Header } from 'components/Header';
import ShareSocial from 'components/ShareSocial/ShareSocial';
import { useSafeParams } from 'hooks/useSafeParams';
import useToggle from 'hooks/useToggle';
import { AppDrawer } from 'components/app-modals';
import AppSpinner from 'components/ui-kit/AppSpinner/AppSpinner';
import { AppBoxWithShadow } from 'components/ui-kit/AppBoxWithShadow';
import AppBtn from 'components/ui-kit/AppBtn/AppBtn';
import { isAdminRoute } from 'features/admin/utils/isAdmin';
import { API_BASE_URL } from 'constants/environment';
import { SharedLinks } from 'features/admin/components/SharedLinks';

import { getMessageSocial, getEarliestDate } from './utils';
import { ControlBooking, ControlBookingDrawer } from './ControlBooking';
import { ResultPrice } from './ResultPrice';
import { StyledResultStep } from './ResultStep.styles';
import { Feedback } from './Feedback/Feedback';
import { ResultStatus } from './ResultStatus';
import { ResultService } from './ResultService';
import { IResultStepOrderItem } from './types';

export const ResultStep = () => {
  const [isInternalCanceled, setCanceled] = useState(false);
  const [showModal, setShowModal] = useToggle(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { publicId } = useSafeParams();
  const [searchParams] = useSearchParams();
  const isAdmin = isAdminRoute();

  const rentalOrderId = Number(searchParams.get('rental_order_id') as string);
  const fullUrl = `${window.location.protocol}//${window.location.host}${location.pathname}${location.search}`;

  const { data: unitedData, isLoading: unitedDataLoading } =
    publicApi.useGetUnitedDataQuery(
      publicId
        ? {
            public_id: publicId,
          }
        : skipToken,
    );

  const {
    data: orderInfo,
    isLoading: orderInfoLoading,
    isError: isOrderInfoError,
    error: orderInfoError,
  } = publicApi.useGetOrderInfoQuery(
    {
      rental_order_id: rentalOrderId,
    },
    {
      refetchOnFocus: true,
      refetchOnMountOrArgChange: true,
    },
  );

  const isCanceled = useMemo(() => {
    return (
      isInternalCanceled || Boolean(orderInfo?.data.result.orders[0].dt_deleted)
    );
  }, [orderInfo?.data.result.orders, isInternalCanceled]);

  const orders = useMemo(() => {
    if (!orderInfo) {
      return [];
    }

    const orderItems = orderInfo.data.result.orders.reduce(
      (res, item) => {
        if (res[item.rental_item_id]) {
          res[item.rental_item_id].slots.push(item);
        } else {
          res[item.rental_item_id] = {
            service: item.rental_item,
            slots: [item],
          };
        }

        return res;
      },
      {} as Record<string, IResultStepOrderItem>,
    );

    return Object.values(orderItems);
  }, [orderInfo]);

  const orderedItemServices = orderInfo?.data.result.orders.flatMap(
    order => order.ordered_item_services,
  );

  const googleLink = useMemo(() => {
    const link = orders
      .map(order => order.slots)
      .flatMap(arr => arr.map(obj => obj.google_event_link))[0];

    if (link) {
      return link + '&ctz=Europe/Moscow';
    }

    return undefined;
  }, [orders]);

  if (isOrderInfoError) {
    // Фикс перехода на онлайн оплату в Сафари из ClientInformation
    if (
      isCancel(orderInfoError) ||
      ('status' in orderInfoError && orderInfoError.status === 'FETCH_ERROR')
    ) {
      return;
    }

    return (
      <StyledResultStep className="result-step">
        <Header onBackClick={() => navigate(`/${publicId}`)} />
        <div className="result-step__spinner">
          <Typography fontSize={16} color="text.error" textAlign="center">
            {t('ORDER_INFO_ERROR')}
          </Typography>
        </div>
      </StyledResultStep>
    );
  }

  if (unitedDataLoading || orderInfoLoading) {
    return (
      <StyledResultStep className="result-step">
        <Header onBackClick={() => navigate(`/${publicId}`)} />
        <div className="result-step__spinner">
          <AppSpinner />
        </div>
      </StyledResultStep>
    );
  }

  const { minDate } = getEarliestDate(orders);
  const user = orderInfo?.data?.result?.user;
  const paymentPageUrl = `${API_BASE_URL}/pay?rental_order_id=${rentalOrderId}`;

  const paymentTypeMap = orders
    .map(order => order.slots)
    .flatMap(arr => arr.map(obj => obj.payment_type_map))[0];

  const isCancelableVisit = orders
    .map(order => order.slots)
    .flatMap(arr => arr.map(obj => obj.cancelable))[0];

  const isPaid = orders
    .map(order => order.slots)
    .flatMap(arr => arr.map(obj => obj.is_paid))[0];

  const isPaymentOnlineCurrent = paymentTypeMap === 'online';
  const isPaymentOnlineAll = Boolean(
    unitedData?.data.project_info.payment_types.filter(
      item => item.map === 'online',
    ).length,
  );
  const messageSocial = getMessageSocial(orders);
  const phoneFeedback = unitedData?.data.project_info.phone;

  const timeLeft = orders
    .map(order => order.slots)
    .flatMap(arr => arr.map(obj => obj.time_left))[0];

  const totalAmount = orderInfo?.data.result.total_amount;
  const totalTime = orderInfo?.data.result.total_time;

  const isSuccess = isPaid == '1'; // TODO: backend стал возвращать number
  const isPending = isPaid == '0';
  const isTimeLeft = Number(timeLeft) > 0;

  return (
    <StyledResultStep className="result-step">
      <Header onBackClick={() => navigate(`/${publicId}`)} />

      <article className="result-step__container">
        <h1 className="result-step__heading visually-hidden">
          {t('RESULT_TITLE')}
        </h1>

        <ResultStatus
          className="result-status"
          isCanceled={isCanceled}
          isSuccess={!isCanceled && isSuccess}
          isPending={!isCanceled && isPending}
          isTimeLeft={isTimeLeft}
          isAdmin={isAdmin}
          isPaymentOnlineCurrent={isPaymentOnlineCurrent}
          isPaymentOnlineAll={isPaymentOnlineAll}
          isError={!isCanceled && isOrderInfoError}
          user={user}
          timeLeft={timeLeft}
        />

        <AppBoxWithShadow className="result-step__box">
          {orders.map((orderItem, index) => (
            <ResultService
              key={index}
              orderItem={orderItem}
              additionalServices={orderedItemServices ?? []}
              minDate={minDate}
              totalTime={totalTime}
            />
          ))}

          <ResultPrice totalCost={Number(totalAmount)} />
        </AppBoxWithShadow>

        {isAdmin && !isCanceled && (
          <>
            <AppBoxWithShadow>
              <SharedLinks orders={orders} paymentPageUrl={paymentPageUrl} />
            </AppBoxWithShadow>
            <AppBtn
              fullWidth
              onClick={() =>
                (window.location.href = `/${publicId}/${Paths.Admin}`)
              }>
              На главную
            </AppBtn>
          </>
        )}

        {!isAdmin && (
          <>
            <ControlBooking
              isSuccess={isSuccess}
              isPending={isPending}
              isCanceled={isCanceled}
              isPaymentOnline={isPaymentOnlineAll}
              isPaymentOnlineCurrent={isPaymentOnlineCurrent}
              isTimeLeft={isTimeLeft}
              isError={isOrderInfoError}
              isCancelableVisit={isCancelableVisit}
              paymentPageUrl={paymentPageUrl}
              googleLink={googleLink}
              onCancel={setShowModal}
            />

            {!isCanceled && (
              <section>
                <Typography
                  className="result-step__social-title"
                  component="h3"
                  color="text.secondary"
                  fontSize={16}
                  lineHeight={1.3}
                  fontWeight={600}>
                  {t('SOCIAL_SHARE')}
                </Typography>
                <ShareSocial
                  url={fullUrl}
                  text={messageSocial}
                  socials={['tg', 'vk', 'viber', 'whatsApp']}
                />
              </section>
            )}

            <AppDrawer
              anchor="bottom"
              onClose={setShowModal}
              open={showModal}
              disableEnforceFocus>
              <ControlBookingDrawer
                rentalOrderId={rentalOrderId}
                onCanceled={() => setCanceled(true)}
                onClose={setShowModal}
              />
            </AppDrawer>

            <Feedback phoneFeedback={phoneFeedback} />
          </>
        )}
      </article>
    </StyledResultStep>
  );
};
