import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { IAdditionalService, IRentalItem, ITimeSlot } from 'types/types';
import { useDateLocale } from 'i18n/useDateLocale';

import { AdditionalServiceForm } from './AdditionalServiceForm';
import { Root } from './SelectAdditionalService.styles';

interface ISelectAdditionalServiceProps {
  className?: string;
  service: IRentalItem;
  additionalServices: IAdditionalService[];
  slots: ITimeSlot[];
}

export const SelectAdditionalService = ({
  className,
  service,
  additionalServices,
  slots,
}: ISelectAdditionalServiceProps) => {
  const { t } = useTranslation();
  const locale = useDateLocale();

  const sortedSlots = useMemo(() => {
    const result = [...slots];

    result.sort(
      (a, b) => Number(a.slot.slice(0, 2)) - Number(b.slot.slice(0, 2))
    );

    return result;
  }, [slots]);

  return (
    <Root className={className}>
      {additionalServices.map((additionalService) => (
        <AdditionalServiceForm
          key={additionalService.id}
          additionalService={additionalService}
          slots={sortedSlots}
          serviceId={service.id}
        />
      ))}
    </Root>
  );
};
