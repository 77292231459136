import React from 'react';
import { createBrowserRouter } from 'react-router-dom';
import { Typography } from '@mui/material';
import i18next from 'i18next';

import { FirstStep } from 'features/firstStep';
import { ResultStep } from 'features/resultStep';
import { PageSelectSlot } from 'features/slot';
import { PageSelectService } from 'features/service';
import { EditBook } from 'features/admin/editBook/EditBook';
import { QuickBook } from 'features/admin/quickBook/QuickBook';

import App from '../App';

import { Paths } from './constants';

const routers = createBrowserRouter([
  {
    path: Paths.Root,
    element: <App />,
    children: [
      {
        index: true,
        element: <FirstStep />,
      },
      {
        path: Paths.Slots,
        element: <PageSelectSlot />,
      },
      {
        path: Paths.Services,
        element: <PageSelectService />,
      },
      {
        path: Paths.Result,
        element: <ResultStep />,
      },
      {
        path: Paths.Admin,
        children: [
          {
            index: true,
            element: <FirstStep />,
          },
          {
            path: Paths.Slots,
            element: <PageSelectSlot />,
          },
          {
            path: Paths.Services,
            element: <PageSelectService />,
          },
          {
            path: Paths.Result,
            element: <ResultStep />,
          },
          {
            path: Paths.EditBook,
            element: <EditBook />,
          },
          {
            path: Paths.QuickBook,
            element: <QuickBook />,
          },
        ],
      },
      {
        path: '*',
        element: <p>404</p>,
      },
    ],
  },
  {
    path: '*',
    element: (
      <Typography
        marginTop={4}
        textAlign="center"
        fontSize={16}
        color="text.primary"
      >
        {i18next.t('APP_NOT_SELECTED')}
      </Typography>
    ),
  },
]);

export default routers;
