import React, { useCallback, useMemo, useState } from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { useFormContext } from 'react-hook-form';

import {
  IAdditionalService,
  IAdditionalServiceOrder,
  ITimeSlot,
} from 'types/types';
import { useDateLocale } from 'i18n/useDateLocale';
import AppCheckbox from 'components/ui-kit/AppCheckbox/AppCheckbox';
import { parseApiDate } from 'utils/parseApiDate';
import { TimeRange } from 'components/TimeRange/TimeRange';
import AppAmount from 'components/ui-kit/AppAmount/AppAmount';
import AppSwitch from 'components/ui-kit/AppSwitch/AppSwitch';
import { isLiteralObject } from 'utils/isLiteralObject';
import AppBtn from 'components/ui-kit/AppBtn/AppBtn';

import { CheckMarkIcon, PlusIcon } from 'assets/images';

import { IAdditionalServiceForm } from '../../AdditionalService/schema';
import { getAdditionalServiceCounts } from '../utils';

import { Root, Price, Slots, SlotItem } from './AdditionalServiceForm.styles';

interface IAdditionalServiceFormProps {
  serviceId: string;
  additionalService: IAdditionalService;
  slots: ITimeSlot[];
}

export const AdditionalServiceForm = ({
  serviceId,
  additionalService,
  slots,
}: IAdditionalServiceFormProps) => {
  const { t } = useTranslation();
  const locale = useDateLocale();

  const { setValue, watch } = useFormContext<IAdditionalServiceForm>();

  const additionalServiceOrders = watch('additionalServiceOrders');

  const selected = useMemo(
    () =>
      additionalServiceOrders.filter(
        (item) =>
          item.serviceId === serviceId &&
          item.additionalServiceId === additionalService.id
      ),
    [additionalService.id, additionalServiceOrders, serviceId]
  );

  const isAllSelected = useMemo(
    () => selected.length === slots.length,
    [selected.length, slots.length]
  );
  const hasSomeSelected = useMemo(() => selected.length > 0, [selected.length]);

  // Пример "считаемых": Аренда гидрофойла, обед
  const isCountable = additionalService.quantity !== null;

  const isExpandable = useMemo(() => {
    if (
      isLiteralObject(additionalService.params) &&
      additionalService.params.all_sets
    ) {
      return false;
    }

    return true;
  }, [additionalService.params]);

  const [expanded, setExpanded] = useState(isExpandable && hasSomeSelected);

  const onChangeCheckbox = useCallback(
    (slot: ITimeSlot) => (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        setValue('additionalServiceOrders', [
          ...additionalServiceOrders,
          {
            serviceId,
            additionalServiceId: additionalService.id,
            slotFrom: slot.from,
            count: 1,
          },
        ]);
      } else {
        const isMatchOrder = (
          order: IAdditionalServiceOrder,
          slotFrom: string
        ) =>
          order.serviceId === serviceId &&
          order.additionalServiceId === additionalService.id &&
          order.slotFrom === slotFrom;

        setValue(
          'additionalServiceOrders',
          additionalServiceOrders.filter((item) =>
            isMatchOrder(item, slot.from) ? false : true
          )
        );
      }
    },
    [additionalService.id, additionalServiceOrders, serviceId, setValue]
  );

  const onSelectAll = useCallback(() => {
    const selectAllAvailableSlots = () => {
      const selected = slots.filter((slot) => {
        const { bookedCount, count } = getAdditionalServiceCounts({
          additionalService,
          additionalServiceOrders,
          serviceId,
          slots: [slot],
        });

        const isAvailableSlot = bookedCount < count;

        return isAvailableSlot;
      });

      setValue('additionalServiceOrders', [
        ...additionalServiceOrders,
        ...selected.map((slot) => ({
          serviceId,
          additionalServiceId: additionalService.id,
          slotFrom: slot.from,
          count: 1,
        })),
      ]);
    };

    selectAllAvailableSlots();
  }, [additionalService, additionalServiceOrders, serviceId, setValue, slots]);

  const onDeselect = useCallback(() => {
    setValue('additionalServiceOrders', [
      ...additionalServiceOrders.filter((order) =>
        order.serviceId === serviceId &&
        order.additionalServiceId === additionalService.id
          ? false
          : true
      ),
    ]);
  }, [additionalService.id, additionalServiceOrders, serviceId, setValue]);

  const onToggleSelect = useCallback(() => {
    if (hasSomeSelected) {
      onDeselect();
    } else {
      onSelectAll();

      if (isExpandable) {
        setExpanded(true);
      }
    }
  }, [hasSomeSelected, isExpandable, onDeselect, onSelectAll]);

  const hasAvailableSlots = useMemo(() => {
    const availableSlots = slots.filter((slot) => {
      const { bookedCount, count } = getAdditionalServiceCounts({
        additionalService,
        additionalServiceOrders,
        serviceId,
        slots: [slot],
      });

      const isAvailableSlot = bookedCount < count;

      return isAvailableSlot;
    });

    return availableSlots.length > 0;
  }, [additionalService, additionalServiceOrders, serviceId, slots]);

  return (
    <Root>
      <Typography
        component="h4"
        fontSize={16}
        fontWeight={500}
        color="text.primary"
        marginBottom="10px"
      >
        {additionalService.name}
      </Typography>
      <Price>
        <Typography
          component="span"
          fontSize={16}
          fontWeight={600}
          color="text.accent"
        >
          <AppAmount amount={Number(additionalService.price)} />
        </Typography>
        {isCountable ? (
          hasSomeSelected ? (
            <AppBtn size="s" onClick={onDeselect} filling="primary">
              <CheckMarkIcon width={18} height={18} />
            </AppBtn>
          ) : hasAvailableSlots ? (
            <AppBtn size="s" onClick={onToggleSelect} filling="secondary">
              <PlusIcon />
            </AppBtn>
          ) : (
            <AppBtn size="s" disabled filling="secondary">
              {t('OUT_OF_QUANTITY')}
            </AppBtn>
          )
        ) : (
          <AppCheckbox
            disabled={!hasAvailableSlots}
            isIndeterminate={!isAllSelected && hasSomeSelected}
            checked={hasSomeSelected}
            onChange={onToggleSelect}
            size="l"
          />
        )}
      </Price>
      {expanded && (
        <Slots>
          <Typography
            component="span"
            fontSize={16}
            fontWeight={500}
            color="text.primary"
            marginBottom="20px"
          >
            {t('SELECT_ADDITIONAL_SERVICES_SLOT')}
          </Typography>
          {slots.map((slot) => {
            const checked =
              additionalServiceOrders.findIndex(
                (item) =>
                  item.serviceId === serviceId &&
                  item.additionalServiceId === additionalService.id &&
                  item.slotFrom === slot.from
              ) !== -1;

            const { bookedCount, count } = getAdditionalServiceCounts({
              additionalService,
              additionalServiceOrders,
              serviceId,
              slots: [slot],
            });

            const disabled = bookedCount >= count;

            return (
              <SlotItem key={slot.from}>
                <AppSwitch
                  className="slot-switch"
                  disabled={disabled}
                  checked={checked}
                  onChange={onChangeCheckbox(slot)}
                  reversed
                  height={30}
                >
                  <TimeRange slot={slot} />{' '}
                  <Typography
                    component="span"
                    fontSize={16}
                    fontWeight={500}
                    color="text.tertiary"
                  >
                    {format(parseApiDate(slot.from), 'd MMMM yyyy, EEEEEE', {
                      locale,
                    })}
                  </Typography>
                </AppSwitch>
              </SlotItem>
            );
          })}
        </Slots>
      )}
    </Root>
  );
};
