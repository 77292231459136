import React, { useMemo, useState } from 'react';
import { IconButton, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { skipToken } from '@reduxjs/toolkit/query';
import { useFormContext } from 'react-hook-form';

import { publicApi } from 'store/api';

import { IRentalItem, ITimeSlot } from 'types/types';
import { useDateLocale } from 'i18n/useDateLocale';
import { parseApiDate } from 'utils/parseApiDate';
import AppSpinner from 'components/ui-kit/AppSpinner/AppSpinner';
import { useSafeParams } from 'hooks/useSafeParams';
import { TimeRange } from 'components/TimeRange/TimeRange';
import AppBtn from 'components/ui-kit/AppBtn/AppBtn';

import { ChevronUpIcon, CrossIcon, PlusIcon } from 'assets/images';

import { SelectAdditionalService } from '../../SelectAdditionalService';
import { IAdditionalServiceForm } from '../schema';

import {
  Root,
  AdditionalAddBtn,
  StyledSlot,
  StyledSlotNum,
  StyledSlots,
  SlotRemoveBtn,
} from './AdditionalServiceSettings.styles';

interface IAdditionalServiceSettingsProps {
  className?: string;
  service: IRentalItem;
  slots: ITimeSlot[];
  onRemoveSlot?: (slot: ITimeSlot) => void;
}

export const AdditionalServiceSettings = ({
  className,
  service,
  slots,
  onRemoveSlot,
}: IAdditionalServiceSettingsProps) => {
  const { t } = useTranslation();
  const locale = useDateLocale();

  const { getValues } = useFormContext<IAdditionalServiceForm>();
  const hasInitialSelected = useMemo(
    () =>
      getValues('additionalServiceOrders').findIndex(
        item => item.serviceId === service.id,
      ) !== -1,
    [getValues, service.id],
  );

  const [openSelection, setOpenSelection] = useState(hasInitialSelected);

  const { publicId } = useSafeParams();
  const { data: additionalServices, isLoading } =
    publicApi.useGetItemServicesQuery(
      publicId
        ? {
            public_id: publicId,
            available: JSON.stringify(
              slots ? slots.map(slot => slot.from) : [],
            ),
          }
        : skipToken,
    );

  return (
    <Root className={className}>
      <StyledSlots>
        {slots.map((slot, index) => (
          <StyledSlot key={slot.from}>
            <StyledSlotNum>{index + 1}</StyledSlotNum>
            <span>
              <TimeRange slot={slot} />
            </span>
            <Typography
              component="span"
              fontSize={16}
              fontWeight={500}
              color="text.tertiary"
              marginLeft="auto">
              {format(parseApiDate(slot.from), 'd MMMM yyyy, EEEEEE', {
                locale,
              })}
            </Typography>
            {onRemoveSlot ? (
              <SlotRemoveBtn
                onClick={() => onRemoveSlot(slot)}
                size="small"
                aria-label="remove">
                <CrossIcon />
              </SlotRemoveBtn>
            ) : null}
          </StyledSlot>
        ))}
      </StyledSlots>
      {openSelection ? (
        <div className="select-additional-service">
          {isLoading ? (
            <AppSpinner />
          ) : (
            <>
              <SelectAdditionalService
                service={service}
                additionalServices={additionalServices?.data.result ?? []}
                slots={slots}
              />
              <AppBtn
                className="hide-additional-service"
                fullWidth
                size="l"
                filling="ascetic"
                RightIcon={ChevronUpIcon}
                onClick={() => setOpenSelection(false)}>
                {t('HIDE_ADDITIONAL_SERVICES')}
              </AppBtn>
            </>
          )}
        </div>
      ) : slots.length > 0 ? (
        <AdditionalAddBtn
          size="l"
          filling="filled"
          RightIcon={PlusIcon}
          onClick={() => setOpenSelection(true)}>
          {t('ADD_ADDITIONAL_SERVICES')}
        </AdditionalAddBtn>
      ) : null}
    </Root>
  );
};
