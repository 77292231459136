import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FormProvider } from 'react-hook-form';
import { skipToken } from '@reduxjs/toolkit/query';
import { toast } from 'react-toastify';

import { privateApi, publicApi } from 'store/api';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { quickBookSlice, quickBookGetOrderItems } from 'store/slices/quickBook';

import { useForm } from 'hooks/useForm';
import { ISelectOption } from 'components/app-selects';
import { useSafeParams } from 'hooks/useSafeParams';
import { SelectSlot } from 'features/slot';
import { IAdditionalServiceOrder, IRentalItem, ITimeSlot } from 'types/types';
import { IAdditionalServiceForm } from 'features/additionalService/AdditionalService/schema';
import { assertIsDefined } from 'utils/assertion';
import { isApiError, isFetchBaseQueryError } from 'utils/network';
import AppSpinner from 'components/ui-kit/AppSpinner/AppSpinner';
import { IQuickBookTimeSlot, ICancelOrder } from 'types/admin';
import { API_BASE_URL } from 'constants/environment';
import { ResultPrice } from 'features/resultStep/ResultPrice';
import { IResultStepOrderItem } from 'features/resultStep/types';
import { isAdminRoute } from 'features/admin/utils/isAdmin';
import { hasAdminToken } from 'utils/token';
import { isLiteralObject } from 'utils/isLiteralObject';
import { AppDrawer } from 'components/app-modals';
import useToggle from 'hooks/useToggle';

import { DeleteIcon } from 'assets/images';
import { PlusIcon } from 'assets/images';

import {
  ClientForm,
  ClientInformationInAdminSchema,
  TClientInformationInAdmin,
} from '../components/ClientForm';
import { StepAction } from '../components/StepAction';
import { ServiceSettings } from '../components/ServiceSettings';
import { PaymentTypeForm } from '../components/PaymentTypeForm';
import { PaymentStatus } from '../components/PaymentStatus';

import {
  RootHeader,
  Root,
  RootContent,
  ServiceItem,
  AddSetBtn,
  CenterContainer,
  StyledDeleteButton,
  Statuses,
} from './EditBook.style';
import { useEditBookSearchParams } from './useEditBookSearchParams';
import { ServiceOrderInfo } from './ServiceOrderInfo';
import { CancelOrderDrawer } from './CancelOrderDrawer';

export const EditBook = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const { rentalOrderId } = useEditBookSearchParams();
  const { publicId } = useSafeParams();

  const {
    data: rentalData,
    isLoading,
    isError: isRentalReadError,
    error: rentalReadError,
  } = privateApi.useRentalVisitsReadQuery(
    rentalOrderId && publicId
      ? {
          public_id: publicId,
          rental_order_id: rentalOrderId,
        }
      : skipToken,
  );

  const { data: unitedData } = publicApi.useGetUnitedDataQuery(
    publicId
      ? {
          public_id: publicId,
        }
      : skipToken,
  );

  const allServicesData = unitedData?.data.rental_items ?? [];

  const allServices = rentalData?.data.result.orders
    .map(item => item.rental_item)
    .concat(allServicesData)
    .filter(
      (item, index, self) =>
        index === self.findIndex(elem => elem.id === item.id),
    );

  const selectedSlots = useAppSelector(state => state.quickBook.slots);
  const orderedItemServices = rentalData?.data.result.orders.flatMap(
    order => order.ordered_item_services,
  );

  const paymentTypeVariants: ISelectOption[] = useMemo(() => {
    const paymentTypes = unitedData?.data.project_info.payment_types;

    if (!paymentTypes) {
      return [];
    }

    return paymentTypes.map(item => ({
      value: item.id ?? '',
      label: item.name,
    }));
  }, [unitedData?.data.project_info.payment_types]);

  const clientInfoFormMethods = useForm<TClientInformationInAdmin>({
    schema: ClientInformationInAdminSchema,
    defaultValues: {
      remind: true,
      remindTime: { value: '2', label: t('REMIND_HOUR', { count: 2 }) },
      payment_type:
        paymentTypeVariants.length > 0
          ? paymentTypeVariants.find(item => item.label === 'Онлайн') ||
            paymentTypeVariants[0]
          : undefined,
      notification: {
        email: true,
        sms: false,
      },
    },
  });

  const additionalServiceOrdersFormMethods = useForm<IAdditionalServiceForm>({
    defaultValues: {
      additionalServiceOrders: [],
    },
  });

  const watchAdditionalServiceOrders = additionalServiceOrdersFormMethods.watch(
    'additionalServiceOrders',
  );

  const isInit = useRef(false);
  useEffect(() => {
    if (rentalData && rentalData && !isInit.current) {
      isInit.current = true;

      const remindTime = isLiteralObject(
        rentalData.data.result.orders[0]?.params,
      )
        ? rentalData.data.result.orders[0]?.params.custom_interval
        : undefined;

      clientInfoFormMethods.reset({
        first_name: rentalData.data.result.user.first_name ?? undefined,
        phone: rentalData.data.result.user.phone ?? undefined,
        email: rentalData.data.result.user.email ?? undefined,
        comment: rentalData.data.result.orders[0]?.comment,
        payment_type: paymentTypeVariants.find(
          payment =>
            payment.value === rentalData.data.result.orders[0]?.payment_type_id,
        ),
        remind: remindTime !== undefined,
        remindTime: remindTime
          ? {
              value: remindTime,
              label: t('REMIND_HOUR', { count: Number(remindTime) }),
            }
          : { value: '2', label: t('REMIND_HOUR', { count: 2 }) },
        notification: {
          email: true,
          sms: false,
        },
      });

      const additionalServiceOrders: IAdditionalServiceOrder[] = [];
      const slots: IQuickBookTimeSlot[] = [];

      rentalData.data.result.orders.forEach(item => {
        slots.push({
          serviceId: item.rental_item_id,
          slot: {
            is_paid: item.is_paid,
            from: item.from,
            to: item.to,
            slot: item.from.slice(11),
            available_items: '',
            available_services: [],
          } as any, // TODO: в IMakeOrderServiceItem не хватает полей для ITimeSlot
        });

        item.ordered_item_service_ids.forEach(additionalServiceId => {
          additionalServiceOrders.push({
            serviceId: item.rental_item_id,
            additionalServiceId,
            slotFrom: item.from,
            count: 1,
          });
        });
      });

      dispatch(quickBookSlice.actions.setSlots(slots));
      additionalServiceOrdersFormMethods.setValue(
        'additionalServiceOrders',
        additionalServiceOrders,
      );
    }
  }, [
    additionalServiceOrdersFormMethods,
    clientInfoFormMethods,
    dispatch,
    paymentTypeVariants,
    rentalData,
    t,
  ]);

  const [serviceSlotSelection, setServiceSlotSelection] = useState<
    IRentalItem | undefined
  >(undefined);

  const onSelectSlot = useCallback(
    (service: IRentalItem) => (serviceSlots: ITimeSlot[]) => {
      const otherServices = selectedSlots.filter(
        slot => slot.serviceId !== service.id,
      );

      dispatch(
        quickBookSlice.actions.setSlots([
          ...otherServices,
          ...serviceSlots.map(slot => ({
            serviceId: service.id,
            slot,
          })),
        ]),
      );
      setServiceSlotSelection(undefined);
    },
    [selectedSlots, dispatch],
  );

  const onRemoveSlot = useCallback(
    (slot: IQuickBookTimeSlot) => {
      dispatch(quickBookSlice.actions.removeSlot(slot));

      const additionalServiceOrders =
        additionalServiceOrdersFormMethods.getValues('additionalServiceOrders');

      additionalServiceOrdersFormMethods.setValue(
        'additionalServiceOrders',
        additionalServiceOrders.filter(order => {
          if (order.serviceId === slot.serviceId) {
            return order.slotFrom !== slot.slot.from;
          } else {
            return true;
          }
        }),
      );
    },
    [additionalServiceOrdersFormMethods, dispatch],
  );

  const paymentPageUrl = `${API_BASE_URL}/pay?rental_order_id=${rentalOrderId}`;

  const isPermissionCancel =
    isAdminRoute() && hasAdminToken()
      ? false
      : !rentalData?.data.result.orders[0].cancelable;
  const isPaid = rentalData?.data.result.orders[0].is_paid === '1';
  const totalAmount = rentalData?.data.result.total_amount;

  // Используется когда isPaid = true
  const orderedServices = useMemo(() => {
    if (!rentalData) {
      return [];
    }

    const orderItems = rentalData?.data.result.orders.reduce(
      (res, item) => {
        if (res[item.rental_item_id]) {
          res[item.rental_item_id].slots.push(item);
        } else {
          res[item.rental_item_id] = {
            service: item.rental_item,
            slots: [item],
          };
        }

        return res;
      },
      {} as Record<string, IResultStepOrderItem>,
    );

    return Object.values(orderItems);
  }, [rentalData]);

  const [rentalVisitUpdate] = privateApi.useRentalVisitsUpdateMutation();
  const onSubmit = useCallback(
    async (values: TClientInformationInAdmin) => {
      try {
        assertIsDefined(publicId);
        assertIsDefined(rentalOrderId);

        const additionalServiceOrders =
          additionalServiceOrdersFormMethods.getValues(
            'additionalServiceOrders',
          );

        await rentalVisitUpdate({
          public_id: publicId,
          rental_order_id: rentalOrderId,
          email: values.email,
          first_name: values.first_name,
          last_name: values.last_name ?? '',
          comment: values.comment,
          phone: values.phone,
          payment_type_id: values.payment_type.value,
          orders: quickBookGetOrderItems({
            slots: selectedSlots,
            additionalServiceOrders,
            remindTime: values.remind ? values.remindTime?.value : undefined,
          }),
        }).unwrap();

        toast.success(t('BOOK_UPDATED'), {
          position: 'bottom-center',
          autoClose: false,
        });
      } catch (e) {
        if (isFetchBaseQueryError(e) && isApiError(e.data)) {
          toast.error(e.data.error?.message, {
            position: 'bottom-center',
            autoClose: false,
          });
        } else {
          toast.error(t('BOOK_ERROR'), {
            position: 'bottom-center',
            autoClose: false,
          });
        }
      }
    },
    [
      additionalServiceOrdersFormMethods,
      publicId,
      rentalVisitUpdate,
      rentalOrderId,
      selectedSlots,
      t,
    ],
  );

  const [isCanceled, setIsCanceled] = useState(false);
  const [cancelResult, setCancelResult] = useState<ICancelOrder | null>(null);
  const isAlreadyOrderCanceled = Boolean(
    rentalData?.data.result.orders[0].dt_deleted,
  );
  const [showCancelModal, toggleCancelModal] = useToggle(false);

  if (isAlreadyOrderCanceled || isCanceled) {
    return (
      <Root>
        <CenterContainer>
          <CancelOrderDrawer
            isOrderCanceled
            rentalOrderId={rentalOrderId as string}
            cancelResult={cancelResult}
            onCanceled={result => {
              setIsCanceled(true);
              setCancelResult(result);
            }}
            onClose={toggleCancelModal}
          />
        </CenterContainer>
      </Root>
    );
  }

  if (isRentalReadError || isRentalReadError) {
    const error = rentalReadError ?? rentalReadError;

    return (
      <Root>
        <CenterContainer>
          <Typography fontSize={16} color="text.error">
            {isFetchBaseQueryError(error) && isApiError(error.data)
              ? error.data.error?.message
              : t('ORDER_INFO_ERROR')}
          </Typography>
        </CenterContainer>
      </Root>
    );
  }

  if (isLoading) {
    return (
      <Root>
        <CenterContainer>
          <AppSpinner />
        </CenterContainer>
      </Root>
    );
  }

  if (serviceSlotSelection) {
    return (
      <Root>
        <SelectSlot
          onBackClick={() => setServiceSlotSelection(undefined)}
          defaultSlots={selectedSlots
            .filter(slot => slot.serviceId === serviceSlotSelection.id)
            .map(slot => slot.slot)}
          onSubmit={onSelectSlot(serviceSlotSelection)}
          services={[serviceSlotSelection]}
          canSubmitEmpty
        />
      </Root>
    );
  }

  return (
    <Root onSubmit={clientInfoFormMethods.handleSubmit(onSubmit)}>
      <RootContent>
        <RootHeader>
          <Typography
            component="h2"
            fontSize={20}
            fontWeight={600}
            color="text.secondary">
            {t('CLIENT_INFO')}
          </Typography>
        </RootHeader>
        <Statuses>
          <PaymentStatus status={isPaid ? 'paid' : 'no-paid'} />
        </Statuses>

        <FormProvider {...clientInfoFormMethods}>
          <ClientForm paymentTypes={paymentTypeVariants} />
        </FormProvider>

        <Typography
          component="h3"
          fontSize={20}
          fontWeight={600}
          color="text.secondary"
          textAlign="center"
          marginTop="30px"
          marginBottom="20px">
          {t('DETAILS')}
        </Typography>

        {isPaid ? (
          <>
            {isLoading ? (
              <AppSpinner className="edit-book__spinner" />
            ) : (
              <>
                {orderedServices.map(orderService => (
                  <ServiceItem key={orderService.service!.id}>
                    <ServiceOrderInfo
                      serviceOrderItem={orderService}
                      additionalServices={orderedItemServices ?? []}
                    />
                  </ServiceItem>
                ))}

                <ResultPrice
                  className="edit-book__result-price"
                  totalCost={Number(totalAmount)}
                />
              </>
            )}
          </>
        ) : (
          <FormProvider {...additionalServiceOrdersFormMethods}>
            {allServices &&
              allServices.map(service => (
                <ServiceItem key={service.id}>
                  <ServiceSettings
                    additionalServiceOrders={watchAdditionalServiceOrders.filter(
                      order => order.serviceId === service.id,
                    )}
                    slots={selectedSlots.filter(
                      slot => slot.serviceId === service.id,
                    )}
                    service={service}
                    onRemoveSlot={onRemoveSlot}
                  />
                  <AddSetBtn
                    size="l"
                    filling="filled"
                    RightIcon={PlusIcon}
                    onClick={() => setServiceSlotSelection(service)}>
                    {t('ADD_SET')}
                  </AddSetBtn>
                </ServiceItem>
              ))}
          </FormProvider>
        )}

        <FormProvider {...clientInfoFormMethods}>
          <PaymentTypeForm
            orders={orderedServices}
            orderInfoLoading={isLoading}
            paymentPageUrl={paymentPageUrl}
            paymentTypes={paymentTypeVariants}
          />
        </FormProvider>

        <StyledDeleteButton
          filling="ascetic"
          RightIcon={DeleteIcon}
          onClick={toggleCancelModal}
          type="button"
          disabled={isPermissionCancel}>
          Удалить визит
        </StyledDeleteButton>
      </RootContent>

      <AppDrawer
        anchor="bottom"
        onClose={toggleCancelModal}
        open={showCancelModal}
        disableEnforceFocus
        ModalProps={{
          disableScrollLock: true,
        }}>
        <CancelOrderDrawer
          cancelResult={cancelResult}
          onCanceled={result => {
            setIsCanceled(true);
            setCancelResult(result);
          }}
          rentalOrderId={rentalOrderId as string}
          onClose={toggleCancelModal}
        />
      </AppDrawer>

      <StepAction
        disabled={selectedSlots.length === 0}
        isLoading={clientInfoFormMethods.formState.isSubmitting}
        type="submit"
        label={t('SAVE')}
      />
    </Root>
  );
};
