import {
  IAdditionalService,
  IAdditionalServiceOrder,
  ITimeSlot,
} from 'types/types';

/**
 * Подсчет количества доп. услуги additionalServiceId в слотах
 * @returns count Общее количество
 * @returns bookedCount Занятое количество доп. услуг в других сервисах в этих же слотах
 */
export const getAdditionalServiceCounts = ({
  additionalService,
  serviceId,
  slots,
  additionalServiceOrders,
}: {
  additionalService: IAdditionalService;
  serviceId: string;
  slots: ITimeSlot[];
  additionalServiceOrders: IAdditionalServiceOrder[];
}) => {
  const isCountable = additionalService.quantity !== null;

  if (!isCountable) {
    return {
      count: Infinity,
      bookedCount: 0,
    };
  }

  const additionalServiceId = additionalService.id;
  let count = 1;

  for (const slot of slots) {
    const availableAdditionalService = slot.available_services.find(
      (availableAdditionalService) =>
        availableAdditionalService.item_service_id === additionalServiceId
    );

    /**
     * Слот 13:30 доступно 5
     * Слот 14:00 доступно 3
     * Максимально возможное количество доп. услуги, которое можно взять - 3
     */
    count = Math.min(availableAdditionalService?.available_count ?? 1, count); // Прибавляется 1 если у доп. услуги нет количества
  }

  const bookedCount = additionalServiceOrders
    .filter(
      (item) =>
        item.serviceId !== serviceId &&
        item.additionalServiceId === additionalServiceId &&
        slots.findIndex((slot) => item.slotFrom === slot.from) !== -1
    )
    .reduce((sum, current) => sum + current.count, 0);

  return {
    count,
    bookedCount,
  };
};
