import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { API_BASE_URL } from 'constants/environment';
import { IApiOnePageResponse, IApiResponse } from 'types/general';
import {
  IRentalVisitCreate,
  IRentalPayload,
  IRentalPayloadDelete,
  IRentalVisitRead,
  IRentalVisitUpdate,
  IRentalVisitDelete,
  ICancelOrderParams,
  ICancelOrder,
} from 'types/admin';
import { IMakeOrderResult } from 'types/types';
import { getAdminToken } from 'utils/token';

const token = getAdminToken();

export const privateApi = createApi({
  reducerPath: 'privateApi',
  baseQuery: fetchBaseQuery({ baseUrl: API_BASE_URL }),
  endpoints: builder => ({
    rentalVisitsCreate: builder.mutation<
      IApiOnePageResponse<IMakeOrderResult>,
      IRentalVisitCreate
    >({
      query: params => ({
        url: '/rental_visits_create/',
        method: 'POST',
        body: {
          token,
          params: {
            ...params,
            orders: `[${params.orders
              .map(order =>
                JSON.stringify({
                  ...order,
                  ordered_item_service_ids:
                    order.ordered_item_service_ids.join(','),
                }),
              )
              .join(',')}]`,
          },
        },
      }),
    }),
    rentalVisitsRead: builder.query<
      IApiOnePageResponse<IRentalVisitRead>,
      IRentalPayload
    >({
      query: params => ({
        url: '/rental_visits_read/',
        method: 'POST',
        body: {
          token,
          params,
        },
      }),
    }),
    rentalVisitsUpdate: builder.mutation<
      IApiResponse<IRentalVisitRead>,
      IRentalVisitUpdate
    >({
      query: params => ({
        url: '/rental_visits_update/',
        method: 'POST',
        body: {
          token,
          params: {
            ...params,
            orders: `[${params.orders
              .map(order =>
                JSON.stringify({
                  ...order,
                  ordered_item_service_ids:
                    order.ordered_item_service_ids.join(','),
                }),
              )
              .join(',')}]`,
          },
        },
      }),
    }),
    cancelOrder: builder.mutation<
      IApiResponse<ICancelOrder>,
      ICancelOrderParams
    >({
      query: params => ({
        url: '/cancel_order/',
        method: 'POST',
        body: {
          token,
          params,
        },
      }),
    }),
    rentalVisitsDelete: builder.mutation<
      IApiResponse<IRentalVisitDelete>,
      IRentalPayloadDelete
    >({
      query: params => ({
        url: '/rental_visits_delete/',
        method: 'POST',
        body: {
          token,
          params: { ...params },
        },
      }),
    }),
  }),
});
