import React from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';

import AppAmount from 'components/ui-kit/AppAmount/AppAmount';
import AppBtn from 'components/ui-kit/AppBtn/AppBtn';
import { ServiceMiniCard } from 'components/ServiceMiniCard';
import useToggle from 'hooks/useToggle';
import { joinClasses } from 'utils/joinClasses';
import { IAdditionalServicesResult } from 'types/types';
import { TimeRange } from 'components/TimeRange/TimeRange';
import { parseApiDate } from 'utils/parseApiDate';
import { useDateLocale } from 'i18n/useDateLocale';

import { DropDownIcon } from 'assets/images';

import { IResultStepOrderItem } from '../types';
import { ServiceBookDate } from '../ServiceBookDate';

import { StyledResultService } from './ResultService.styles';

type TResultService = {
  orderItem: IResultStepOrderItem;
  additionalServices: IAdditionalServicesResult[];
  minDate: number | undefined;
  totalTime?: number;
};

export const ResultService = (props: TResultService) => {
  const { orderItem, additionalServices, minDate, totalTime } = props;
  const { t } = useTranslation();
  const [showSlots, toggleSlots] = useToggle(false);
  const locale = useDateLocale();

  return (
    <StyledResultService className="result-service">
      <header className="result-service__date">
        {minDate ? <ServiceBookDate minDate={new Date(minDate)} /> : null}
        {/* <AppBtn className="result-service__geolocation" LeftIcon={MapPointIcon} filling="ascetic" iconHeight={34} /> */}
      </header>

      <div className="result-step__wrap">
        {orderItem.service ? (
          <ServiceMiniCard
            item={orderItem.service}
            label={t('RENT_TIME', { time: totalTime })}
          />
        ) : null}
      </div>

      <section className="result-slots">
        <ul className="result-slots__list">
          {orderItem.slots.map((orderSlot, index) => (
            <li
              className="result-slots__element"
              key={orderSlot.id}
              style={{ display: index === 0 || showSlots ? 'block' : 'none' }}>
              <div className="result-slots__wrap">
                <time className="result-slots__date">
                  {format(parseApiDate(orderSlot.from), 'd MMMM yyyy, EEEEEE', {
                    locale,
                  })}
                </time>
                <p className="result-slots__slot">
                  <TimeRange slot={orderSlot} />
                </p>

                <AppAmount
                  className="result-slots__total"
                  amount={Number(orderSlot.price)}
                />
              </div>

              {orderSlot.ordered_item_service_ids.map(additionalServiceId => {
                const additionalService = additionalServices.find(
                  item => item.item_service_id === additionalServiceId
                );

                if (!additionalService) {
                  return null;
                }

                return (
                  <div
                    className="result-slots__services"
                    key={additionalServiceId}>
                    <p className="result-slots__service-name">
                      {additionalService.name}
                    </p>
                    <p className="result-slots__service-price">
                      <AppAmount amount={Number(additionalService.price)} />
                    </p>
                  </div>
                );
              })}
            </li>
          ))}
        </ul>

        {orderItem.slots.length > 1 && (
          <AppBtn
            className={joinClasses(
              'result-step__control',
              showSlots && 'result-step__control_active',
            )}
            onClick={toggleSlots}
            fullWidth
            filling="border"
            RightIcon={DropDownIcon}>
            {showSlots ? 'Свернуть' : 'Подробнее'}
          </AppBtn>
        )}
      </section>
    </StyledResultService>
  );
};
